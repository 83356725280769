// src/context/LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';
import { navigate } from 'gatsby';

export const LanguageContext = createContext({ locale: 'en', toggleLocale: () => {} });

export const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState('en');
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);

    const updateLocale = () => {
      if (typeof window !== 'undefined') {
        const pathParts = window.location.pathname.split('/').filter(Boolean);
        const pathLocale = pathParts[0];

        if (pathLocale === 'ar' || pathLocale === 'en') {
          setLocale(pathLocale);
        } else {
          setLocale('en'); // Default to 'en' if no language is found in the path
        }
      }
    };

    updateLocale();

    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', updateLocale);
      return () => {
        window.removeEventListener('popstate', updateLocale);
      };
    }
  }, []);

  const toggleLocale = () => {
    if (typeof window === 'undefined') return;

    const newLocale = locale === 'en' ? 'ar' : 'en';
    setLocale(newLocale);

    const pathParts = window.location.pathname.split('/').filter(Boolean);
    
    if (locale === 'en') {
      pathParts.unshift('ar');
    } else {
      if (pathParts[0] === 'ar') {
        pathParts.shift();
      }
    }

    const newPath = `/${pathParts.join('/')}${window.location.search}`;
    navigate(newPath);
  };

  if (!isClient) {
    return null; // or a loading indicator
  }

  return (
    <LanguageContext.Provider value={{ locale, toggleLocale }}>
      {children}
    </LanguageContext.Provider>
  );
};
