exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ar-about-js": () => import("./../../../src/pages/ar/about.js" /* webpackChunkName: "component---src-pages-ar-about-js" */),
  "component---src-pages-ar-blog-[slug]-js": () => import("./../../../src/pages/ar/blog/[slug].js" /* webpackChunkName: "component---src-pages-ar-blog-[slug]-js" */),
  "component---src-pages-ar-blog-detail-js": () => import("./../../../src/pages/ar/blog-detail.js" /* webpackChunkName: "component---src-pages-ar-blog-detail-js" */),
  "component---src-pages-ar-blog-js": () => import("./../../../src/pages/ar/blog.js" /* webpackChunkName: "component---src-pages-ar-blog-js" */),
  "component---src-pages-ar-careers-js": () => import("./../../../src/pages/ar/careers.js" /* webpackChunkName: "component---src-pages-ar-careers-js" */),
  "component---src-pages-ar-clients-js": () => import("./../../../src/pages/ar/clients.js" /* webpackChunkName: "component---src-pages-ar-clients-js" */),
  "component---src-pages-ar-contact-us-js": () => import("./../../../src/pages/ar/contact-us.js" /* webpackChunkName: "component---src-pages-ar-contact-us-js" */),
  "component---src-pages-ar-index-js": () => import("./../../../src/pages/ar/index.js" /* webpackChunkName: "component---src-pages-ar-index-js" */),
  "component---src-pages-ar-industries-js": () => import("./../../../src/pages/ar/industries.js" /* webpackChunkName: "component---src-pages-ar-industries-js" */),
  "component---src-pages-ar-projects-[slug]-js": () => import("./../../../src/pages/ar/projects/[slug].js" /* webpackChunkName: "component---src-pages-ar-projects-[slug]-js" */),
  "component---src-pages-ar-projects-detail-js": () => import("./../../../src/pages/ar/projects-detail.js" /* webpackChunkName: "component---src-pages-ar-projects-detail-js" */),
  "component---src-pages-ar-projects-js": () => import("./../../../src/pages/ar/projects.js" /* webpackChunkName: "component---src-pages-ar-projects-js" */),
  "component---src-pages-ar-services-ai-software-development-services-js": () => import("./../../../src/pages/ar/services/ai-software-development-services.js" /* webpackChunkName: "component---src-pages-ar-services-ai-software-development-services-js" */),
  "component---src-pages-ar-services-digital-marketing-strategy-consultancy-js": () => import("./../../../src/pages/ar/services/digital-marketing-strategy-consultancy.js" /* webpackChunkName: "component---src-pages-ar-services-digital-marketing-strategy-consultancy-js" */),
  "component---src-pages-ar-services-js": () => import("./../../../src/pages/ar/services.js" /* webpackChunkName: "component---src-pages-ar-services-js" */),
  "component---src-pages-ar-services-mobile-app-development-js": () => import("./../../../src/pages/ar/services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-ar-services-mobile-app-development-js" */),
  "component---src-pages-ar-services-professional-seo-services-js": () => import("./../../../src/pages/ar/services/professional-seo-services.js" /* webpackChunkName: "component---src-pages-ar-services-professional-seo-services-js" */),
  "component---src-pages-ar-services-social-media-management-services-js": () => import("./../../../src/pages/ar/services/social-media-management-services.js" /* webpackChunkName: "component---src-pages-ar-services-social-media-management-services-js" */),
  "component---src-pages-ar-services-web-development-js": () => import("./../../../src/pages/ar/services/web-development.js" /* webpackChunkName: "component---src-pages-ar-services-web-development-js" */),
  "component---src-pages-blog-[slug]-js": () => import("./../../../src/pages/blog/[slug].js" /* webpackChunkName: "component---src-pages-blog-[slug]-js" */),
  "component---src-pages-blog-detail-js": () => import("./../../../src/pages/blog-detail.js" /* webpackChunkName: "component---src-pages-blog-detail-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-projects-[slug]-js": () => import("./../../../src/pages/projects/[slug].js" /* webpackChunkName: "component---src-pages-projects-[slug]-js" */),
  "component---src-pages-projects-detail-js": () => import("./../../../src/pages/projects-detail.js" /* webpackChunkName: "component---src-pages-projects-detail-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-ai-software-development-services-js": () => import("./../../../src/pages/services/ai-software-development-services.js" /* webpackChunkName: "component---src-pages-services-ai-software-development-services-js" */),
  "component---src-pages-services-digital-marketing-strategy-consultancy-js": () => import("./../../../src/pages/services/digital-marketing-strategy-consultancy.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-strategy-consultancy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-mobile-app-development-js": () => import("./../../../src/pages/services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-js" */),
  "component---src-pages-services-professional-seo-services-js": () => import("./../../../src/pages/services/professional-seo-services.js" /* webpackChunkName: "component---src-pages-services-professional-seo-services-js" */),
  "component---src-pages-services-social-media-management-services-js": () => import("./../../../src/pages/services/social-media-management-services.js" /* webpackChunkName: "component---src-pages-services-social-media-management-services-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */)
}

